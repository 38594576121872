.header_nav {
    padding-top: 10px;
    padding-bottom: 10px;
    height: 65px;

    top: 0px;
    box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.2);
    position: fixed;
    background-color: #f1f1f1;
    width: 100%;
}

.header_nav_navlink {
    color: rgb(106, 106, 106);
    display: flex;
    align-items: center;
    text-decoration: none;
    height: 100%;
    cursor: pointer;
    margin-left: 20px;
    margin-right: 20px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 22pt !important;
}

.brand {
    font-size: 22pt;
    font-family: "Montserrat:900", sans-serif !important;
    color: rgb(23, 23, 23)
}

.header_nav_navlink .active {
    color: rgb(23, 23, 23);
}


.header_nav_navmenu {
    display: flex;
    align-items: center;
    margin-right: -24px;
    /* Second Nav */
    /* margin-right: 24px; */
    /* Third Nav */
    /* width: 100vw;
    white-space: nowrap; */
    @media screen and (max-width: 768px) {
        display: none;
    }
}

.header_nav_dropdown_name {
    cursor: default !important;
    font-weight: bold;

}


.sub_header_nav {
    padding-top: 10px;
    padding-bottom: 10px;
    height: 65px;

    top: -25px;
    position: fixed;
    width: 100%;
    border-bottom: 1px solid #222121;
}

.sub_header_nav_navlink {
    font-size: 19pt !important;
}