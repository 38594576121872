.message {
    padding-top: 10px;
    padding-left:15px;
    padding-bottom:10px;
    padding-right:15px;
    width: 300px;
    border-radius: 10px;
    background-color: rgb(52, 52, 230);
    color: white !important;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    white-space: pre-line;
}

.message:disabled {
    color: white !important;
}