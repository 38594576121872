.preview_unit {
    height: 300px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 5px 5px 5px 5px rgba(144, 144, 144, 1);
}

.preview_unit:hover {
    cursor: pointer;
}

.linkToUnit {
    text-decoration: none!important;
    color: black;

}

a:link {
    text-decoration: none!important;
}