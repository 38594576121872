
.table_head_title {
color: #ffffff !important;
font-weight: bold !important;
font-size: 15pt !important;
background-color: blue !important;
padding-top: 10px !important;
padding-bottom: 10px !important;
min-width: 100px !important;
}

table th {
color: #ffffff;
text-align: left;
font-weight: bold;
}

table th {
padding: 12px 15px;
}

table td {
padding: 12px 15px;
max-width: 250px;
word-wrap: break-word;
}

table tbody tr {
border-bottom: 1px solid #dddddd;
}

table tbody tr:nth-of-type(even) {
background-color: #f3f3f3;
}

table tbody tr.active-row {
font-weight: bold;
color: #009879;
}

.table_cell_link {
text-decoration: none ;
font-size: 12pt;
color: rgb(62, 62, 62) ;
font-weight: bold;

}

.table_cell_link:hover {
    color: rgb(23, 23, 23);
}


.expired_background {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(208, 208, 208, 0.6);
    z-index: 100;
}

.table_expired_employees {
    position: fixed; 
    top: 10%;
    bottom: 10%;
    left: 10%;
    right: 10%;
    align-items: center;
    display: flex;
    z-index: 101;
}

.table_body_title {
    font-size: 12pt !important;
}

table {
    font-family: 'Montserrat', sans-serif !important;
}

.sortable-list {
    font-weight: bold !important;
    font-size: 15pt !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    min-width: 100px !important;
    margin-right: 20px;
    margin-left: 20px;
}

.sortable-list-item {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    background-color: #ffffff;
    border-bottom: 1px solid lightgray;
}