html,
body {
  margin: 0;
  font-family: "Montserrat", sans-serif !important;
}

html {
  background: #f1f1f1;
}

body {
  background: white;
}

code {
  font-family: "Montserrat", sans-serif;
}

.main_app {
  text-align: left;
  margin-top: 65px;
  padding-top: 40px;
  padding-bottom: 40px;

  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 60px;
  background: white !important;
}