.main-content-results-unit {
  border: 1px solid lightgrey;
  border-radius: 3px;
  box-shadow: 2px 5px rgb(240, 240, 240);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom:15px;
  margin-bottom: 30px;
}
.main-content-results-unit:hover{
  cursor:pointer;
}

.main-content-results-unitSelected {
  font-weight: 600;
  width: 400px;
  border-right: 4px solid blue;
}

.main-content-results-unit-category {
  color: grey;
  text-decoration: underline;
  margin-bottom: 10px;
}

.main-content-results-unit-details-text {
  float: left;
  width: 80%;
}
  
.main-content-results-unit-details-satis {
  float: right;
  width: 20%;
  text-align: right;
  color: orange;
}

.main-content-results-unit-details p {
  margin: 0;
}

.addMessageButton:hover {
  cursor: pointer;
  box-shadow: 5px 5px rgb(152, 197, 252);
}

.buttonRS {
  border-radius: 10px;
  padding: "10px 10px 5px 5px";
  font-weight: bold;
  font-size: 12pt;
  color: black
}