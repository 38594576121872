.main_app {
  text-align: left;
  margin-top: 30px;
  padding-top: 0px;
  padding-bottom: 40px;

  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 60px;
  background: white !important;
}

h2 {
  margin-bottom: 30px;
}

.submitButton {
  background: black !important;
  border: 0 none !important;
  -webkit-border-radius: 5px !important;
  border-radius: 4px !important;
  color: white !important;
  margin-bottom: 20px !important;
}